import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
// import lang from '@scoreonetech/website_lang'
import lang from './lang'
import 'babel-polyfill'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import 'vue2-animate/dist/vue2-animate.min.css'

Vue.use(vueSwiper)
Vue.use(VueI18n)
Vue.config.productionTip = false

const { en, zh, vn, ph } = lang
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: en,
    zh: zh,
    vn: vn,
    ph: ph
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
