<template>
<div id="app">
  <Header />
  <transition name="opacity" mode="out-in">
    <router-view></router-view>
  </transition>
  <Footer />
</div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import 'vue2-animate/dist/vue2-animate.min.css'
import 'swiper/dist/css/swiper.css'

export default {
  name: 'app',
  data: function () {
    return {
      show: false
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
  },
  mounted() {
    //   设置全局语言切换
    const lang = this.$route.query.lang || localStorage.lang
    if (lang && ['en', 'zh', 'vn', 'ph'].includes(lang)) {
      this.$i18n.locale = lang
      localStorage.lang = lang
    }
  }
}
</script>

<style>
@import url('./styles/common.css');
@import url('./styles/media.css');

#app {
  font-family: 'Microsoft YaHei', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 102px;
}

/*  过渡动画 */
.opacity-enter-active {
  animation: opacity 0.5s;
}
.opacity-leave-active {
  animation: opacity 0.3s reverse;
}
@keyframes opacity {
  0% { opacity: 0; }
  25% { opacity: 0.25; }
  50% { opacity: 0.5; }
  75% { opacity: 0.75; }
  100% { opacity: 1; }
}
</style>
