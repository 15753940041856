<template>
  <footer>
    <div class="footer-container">
      <main class="main-content">
        <ul v-for="(item,index) in footerContent" :key="index">
          <li v-for="(liItem,i) in item" :key="i">{{liItem}}</li>
        </ul>
        <ul class="footer-contact">
          <li>Follow us</li>
          <li class="link"><a href="https://www.linkedin.com/company/scoreone-technologies"><img src="./../assets/linkedin.png" alt="linkedin" /></a></li>
          <li>Contact us</li>
          <li class="email"><a href="mailto:service@scoreonetech.com">service@scoreonetech.com</a></li>
        </ul>
      </main>
      <div class="copyright">
        <p>©2020 by Scoreone Financing Corp</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data: function() {
    return {
      footerContent: [
        { title: 'HOME', subTitle1: 'SCOREONE SERVICES', subTitle2: 'SCOREONE PARTNERSHIPS', subTitle3: 'ABOUT SCOREONE' },
        { title: 'TECH SERVICES', subTitle1: 'APPLICATION', subTitle2: 'PRINCIPLE', subTitle3: 'HOW IT WORKS', subTitle4: 'IMPROVEMENT' },
        { title: 'SUSTAINABILITY', subTitle1: 'OUR COMMITMENT', subTitle2: 'WE MADE IT POSSIBLE' },
        { title: 'ABOUT US', subTitle1: 'KNOW WHO WE ARE', subTitle2: 'OUR OFFICE', subTitle3: 'KEY FIGURES' }
      ]
    }
  }
}
</script>

<style lang='scss'>
footer {
  width: 100%;
  min-width: 1200px;
  background: url('./../assets/footer_bg.png') no-repeat;
  background-size: 100% 100%;
  .footer-container {
    width: 1200px;
    height: 366px;
    margin: 0 auto;
    text-align: left;
    font-size: 16px;
    color: #C6DDFF;
    .main-content {
      display: flex;
      justify-content: space-between;
      .footer-contact {
        font-weight: bold;
        color: #fff;
        .link {
          img {
            width: 20px;
            vertical-align: top;
          }
        }
        .email {
          a {
            font-weight: normal;
            color: #C6DDFF;
          }
        }
      }
      ul > li {
        margin-top: 30px;
        &:nth-child(1) {
          font-weight: bold;
          color: #fff;
        }
      }
    }
    .copyright {
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      margin-top: 60px;
    }
  }
}
</style>
