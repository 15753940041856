export default {
  Header: {
    logo_title: 'Scoreone Financing Corp',
    home: '主页',
    tech_services: '技术服务',
    sustainability: '可持续开发',
    investors: '投资',
    about_us: '关于我们',
    contact: '联系我们'
  },
  Home: {
    services: {
      title: 'SCOREONE服务',
      sub_title: '- 金融科技 -',
      text: 'ScoreOne的核心使命是为了解决我们的金融客户所面临的关键问题，我们为您提供定制化服务和合作伙伴关系，去评估个体的信用。'
    },
    detection: {
      ic: '身份查询',
      fd: '欺诈识别',
      cs: '信用评分',
      button: '发现更多',
      ic_title: '帮助您反欺诈',
      ic_sub_title: '确保您的线上客户的真实性。',
      ic_text1: '随着全球信誉造假的多样化，欺诈性的网络活动已日益成为一个国际问题。',
      ic_text2: '我们帮助您发现问题，解决问题并防止问题再次发生。',
      cs_title: 'SCOREONE评估个体信用。',
      cs_sub_title: '无风险分期获客。',
      cs_text1: '在部分发展中国家，大多数人都没有银行卡，这意味着他们没有纸质信用评分，因此他们的信用评估更是难上加难。 但是这些人也代表了一个巨大的潜在市场。',
      cs_text2: '让我们帮助您！确定哪些个人可以受到贷款的信任，无论他们的官方财务状况如何。'
    },
    partnerships: {
      title: 'SCOREONE合作伙伴',
      sub_title: '- 金融科技 -',
      text: '使用最先进的相同技术，我们陪同3个国家/地区的合作伙伴和客户的日常业务。 我们能够评估其潜在客户的可靠性和还款能力。 因此，ScoreOne参与了发展中国家的金融普惠。'
    },
    map: {
      in: '印度',
      vi: '越南',
      ph: '菲律宾',
      countryIntroduce: {
        'Country profile of the Philippines': '菲律宾的国家概况',
        'Country profile of Vietnam': '越南的国家概况',
        'Country profile of India': '印度的国家概况',
        Population: '人口',
        'GDP / capital': 'GDP /资本',
        'GDP growth (2018)': 'GDP增长（2018）',
        'Internet penetration': '互联网渗透率',
        'Rank for number of users': '用户数量排名',
        'Bank account penetration': '银行帐户渗透率',
        'Credit card penetration': '信用卡渗透率',
        'Borrowing habits': '贷款率'
      }
    },
    case: {
      title: '创造更多机遇',
      text: '许多资金不足的人都在努力工作。 我们的产品和技术使我们可以信任传统银行无法做到的。 有了这种信任，他们就可以拥有额外的资金来进一步扩展业务，发展，投资或仅仅满足紧急需求。',
      button: '了解他们的故事'
    },
    about: {
      title: '关于SCOREONE',
      sub_title1: '我们的愿景、使命和目标',
      visions: '我们的愿景',
      v_text: '第四次工业革命是建立信任的好时机，大数据是重新设计我们对信任的定义的机会，以扩大潜在客户基础。',
      missions: '我们的使命',
      m_text: '我们分析了成千上万个变量，使您可以信任新客户和合作伙伴，无论他们以前的征信历史如何。',
      goals: '我们的目标',
      g_text: '改善业务和财务的包容性，利用基于大数据分析来增强合作伙伴之间的信任。',
      sub_title2: '我们的团队和办公室',
      button: '发现更多',
      sub_title3: '可持续发展与承诺',
      sac_text1: '改善所有类型的金融包容性',
      sac_text2: '为信任的蓬勃发展而创造条件',
      sac_text3: '增加业务的互动和容量',
      sac_text4: '利用大数据来推动长期增长',
      sac_text5: '保证数据安全性'
    }
  },
  TechServices: {
    application: {
      title: '应用于您的业务',
      ic_title: '身份检查',
      ic_text: '如果您需要验证客户的身份，我们将为您提供完美的解决方案。 使用诸如透视校正，光学字符识别和实时数据分析等高科技协议，我们可以将ID与实时图片进行比较，并在几秒钟内确认客户的身份',
      fi_title: '诈骗识别',
      fi_text: '我们的反欺诈实时检测技术使您可以放心地与客户互动。 在几秒钟内，一系列的反欺骗措施，图像分析和面部识别可以最准确地识别欺诈行为，同时通过加密来保护数据隐私',
      cs_title: '信用评分',
      cs_text: '我们的算法可以在不到两分钟的时间内分析成千上万的变量，以确定配置文件是适合您的业务目标还是适合您的选定目标。 然后，尽管缺乏传统信息，但信用评分仍归因于个人，代表其可信赖性'
    },
    main_content: {
      first_title: '信任更多人，扩大客户基础',
      first_text1: '信任是关于两件事的：个人关系或足够的信任证明。',
      first_text2: '我们提供最先进的技术，使您可以信任从未见过且从未与金融机构接触过的客户或合作伙伴。',
      first_text3: '资金不足的人正陷入贫困。他们没有足够的钱从事传统的银行服务，因此通常被遗忘。但是，被排除在金融服务之外并不意味着不值得信赖：我们会根据与传统方式不同的方式帮助您扩大客户基础。',
      first_text4: '借助新技术，大数据和物联网所提供的机会，我们创建了一个可持续的计划，无论是传统银行留下的陌生人还是可以被信任，成为您的客户或合作伙伴。',
      second_title: '如何运作',
      second_text: '为了评估一个人的信用评分，考虑了5000多个个体众多变量。从琐碎的信息（如年龄和性别）到最复杂的信息（如旅行次数和个人流动性），所有数据都在不到两分钟的时间内得到了分析。 然后，先进的技术可以为每个人量身定制一个信用评分。',
      third_title: '持续的提升',
      third_text: '机器学习和尖端技术遵循机器学习的思想，我们的算法一直在不断从错误中学习。它每秒都会重新调整以确保技术的最新水平。'
    }
  },
  Sustainability: {
    main_content: {
      first_title: '赋能于缺少资金的人',
      first_text: '传统金融服务忽略了世界三分之一的人口，但是绝大多数人通过自己的智能手机连接到网络，ScoreOne正在努力为人们提供基于大数据和其他信息（通常被传统金融服务所忽略）的信用评分，以增强发展中国家的金融包容性。',
      second_title: '致力于可持续发展',
      second_text: '金融包容性使人们能够获得贷款，从而有可能发展自己的业务，应对紧急情况并最终摆脱贫困陷阱。帮助金融服务提供商首次获得人们的信任正在为可持续发展铺平道路，因为它有助于实现联合国列出的可持续发展目标。',
      third_title: '合理负责',
      third_text: '我们的算法的创建是为了确保可信赖的人们能够通过其信用评分传统上无法访问的服务或商品。我们强调机器学习，以避免不负责任的贷款。通常将那些具有财务危险行为的人排除在外，以避免为我们的客户积累债务和困难。通过信用评分技术，我们可以避免风险并确保没有人能长期承受无法提供的财务选择，从而帮助我们的客户向合适的人提供合适的服务'
    },
    bar_title: '用户故事',
    users: {
      hint1: '要求公开发表意见。',
      hint2: '如需获取更多信息，请联系我们。',
      Melvie: 'Melvie住在菲律宾。像许多当地人一样，她累积了两项工作。她是当地老师，而且还拥有洗车业务。去年，借助ScoreOne Technologies提供的数据服务，她首次能够从我们的当地合作伙伴那里获得贷款。我们分析了数百个数据，我们可以确定Melvie值得信赖且努力工作，而据报道，传统金融机构会向她敞开大门。从那以后，她成为我们合作伙伴最好的客户之一。她设法通过购买其他机器来扩展业务，并且在她的侄子住院支付账单后能够迅速获得资金。大数据使她受到信任，并在财务上得到了包容和信任，有效地改善了她的生活条件。',
      April: '两年来，April一直是我们合作伙伴最忠实的客户之一。她是菲律宾一所公立学校的老师，她的薪水通常会延迟，这经常会带来经济困难。在社交媒体上看到我们合作伙伴的针对性广告后，她决定申请。她的申请处理得非常快。根据她的合格信用评分，大数据技术确定她是可信赖的借款人。钱很快被支付了，使她能够无延迟地支付账单。',
      John: 'John是一名保安，是菲律宾一家小型便利店的所有者。他很自豪地形容自己是一个非常努力的人。银行通常会基于他不稳定的收入而拒绝他的个人资料。反映他令人印象深刻的职业道德，我们的金融科技服务迅速为John授予了很高的信用评分，使他首次获得了金融服务。赋予约翰以信任的权力，使他可以在薪金延迟时继续为商店提供货源，从而使他的生意继续发展并赢得声誉。他向朋友和家人推荐了我们合作伙伴的应用程序，并强调了服务程序的速度和可靠性'
    }
  },
  Investor: {
    title: '成功数据',
    text1: '得益于我们的客户，世界各地越来越多的人可以使用改变生活的金融服务。',
    text2: '我们使客户能够获得: ',
    text3: '我们是谁 + 投资者',
    data_title1: '注册用户数',
    data_title2: '收到的贷款申请',
    data_title3: '提供的贷款',
    USD: '美元',
    millions: '百万',
    more_than: '超过'
  },
  AboutUs: {
    introduce: {
      title: '了解我们',
      sub_title1: '我们的愿景',
      v_text: 'ScoreOne正在构想一个人们因信用而值得信赖的世界。 我们正在努力创造一种互信的替代方式，以改善双方的福利。 对于企业而言，这是寻找新的可靠客户的方法； 对于个人而言，这是获得合法应得的服务或商品的途径。 ScoreOne致力于可持续发展，通过利用大数据的潜力来增强金融包容性',
      sub_title2: '我们的使命',
      m_text: '我们是替代金融解决方案的金融科技提供商，为您提供于信用评分，欺诈检测和身份检查等服务。 通过向资金不足的人群提供移动金融服务，它将创新带入新兴市场。 我们公司的产品使用先进的深度机器学习算法和大数据来提供实时的信用评分和即服务借贷（LaaS）解决方案，从而为各种业务目标扩展客户基础'
    },
    corporateTeams: {
      title: '我们的企业团队',
      users: {
        BruceHuang: {
          name: '黄海旻',
          position: '总裁',
          details: '黄海旻（Bruce Huang）是金融科技公司ScoreOne Technology的联合创始人兼总裁，该公司为东南亚消费者金融市场提供借贷即服务（LaaS）。作为一名技术企业家，他在北美，中国和东南亚地区拥有超过15年的工作经验。他曾在Microsoft担任了7年的美国高级工程师主管，在阿里巴巴云（Alibaba Cloud）担任工程总监，在中国宜信集团（CaseEase Group）担任宜人贷的首席技术官，这是美国上市的第一个市场借贷平台（NYSE：YRD）。在创建ScoreOne之前，他曾是中国顶级市场借贷公司Madai Wealth的首席执行官。'
        }
      }
    },
    office: {
      title: '我们的办公室'
    },
    figures: {
      title: 'ScoreOne的关键数据',
      text1: '得益于我们的客户，世界各地越来越多的人可以使用改变生活的金融服务。',
      text2: '我们使客户能够获得: ',
      text3: '（最新更新：2019年11月）',
      data_title1: '注册用户数',
      data_title2: '收到的贷款申请',
      data_title3: '提供的贷款',
      USD: '美元',
      millions: '百万',
      more_than: '超过'
    }
  },
  Contact: {
    title: '联系我们',
    form: {
      name: '您的名字：',
      name_required: '请输入您的名字 !',
      email: '邮箱：',
      email_required: '请输入您的邮箱 !',
      subject: 'Subject：',
      hint: '请输入：',
      choose: '请选择：',
      option1: 'to invest',
      option2: 'about our corporate solution',
      option3: 'about our company (media & PR)',
      submit: '提交',
      message: '提交成功 !'
    }
  }
}
