import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/home'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/home',
      alias: '/',
      component: Home
    },
    {
      path: '/techservices',
      component: () => import('../views/techServices.vue')
    },
    {
      path: '/sustainability',
      component: () => import('../views/sustainability.vue')
    },
    {
      path: '/investors',
      component: () => import('../views/inverstors.vue')
    },
    {
      path: '/about',
      component: () => import('../views/aboutUs.vue')
    },
    {
      path: '/contact',
      component: () => import('../views/contact.vue')
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
