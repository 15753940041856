export default {
  Header: {
    logo_title: 'Scoreone Financing Corp',
    home: 'Home',
    tech_services: 'Tech Services',
    sustainability: 'Sustainability',
    investors: 'Investor',
    about_us: 'About Us',
    contact: 'Contact'
  },
  Home: {
    services: {
      title: 'SCOREONE SERVICES',
      sub_title: '- Technology for Finance -',
      text: 'ScoreOne exists to solve the critical issues facing our financial clients, both large and small. We offer customized services and partnership to assess the creditworthiness of individuals.'
    },
    detection: {
      ic: 'Identity Check',
      fd: 'Fraud Detection',
      cs: 'Credit Scoring',
      button: 'Find Out More',
      ic_title: 'SCOREONE HELPS YOU FIGHT FRAUD',
      ic_sub_title: 'Make sure your e-clients are real.',
      ic_text1: 'With the multiplication of click-farms round the world, fraudulent online activities and requests are increasingly becoming an international issue.',
      ic_text2: 'We can help you identify the problem, solve it and prevent it from happening again.',
      cs_title: "SCOREONE ASSESS PEOPLE'S CREDIT SCORE",
      cs_sub_title: 'Get more customers through installment without taking risks.',
      cs_text1: "In developing countries, most people are under-banked. That means they don't have a credit-score on paper, so it is impossible to assess their trustworthiness. But these people also represent a large portion of a potential market.",
      cs_text2: 'Let us help you to figure out which individual can be trusted as a client, regardless of their official finance situation.'
    },
    partnerships: {
      title: "FINANCE WITH TECHNOLOGY SCOREONE'S PARTNERSHIP",
      sub_title: '- Our clients around the world -',
      text: 'Using the cutting-edge same technology, we accompany our partners and clients across 3 countries in their every-day businesses. We are able to assess their potential clients’ reliability and capacities to repay installments. ScoreOne thus participate in the financial inclusion in developing countries.'
    },
    map: {
      in: 'India',
      vi: 'Vietnam',
      ph: 'Philippines',
      countryIntroduce: {
        'Country profile of the Philippines': 'Country profile of the Philippines',
        'Country profile of Vietnam': 'Country profile of the Vietnam',
        'Country profile of India': 'Country profile of the India',
        Population: 'Population',
        'GDP / capital': 'GDP / capital',
        'GDP growth (2018)': 'GDP growth (2018)',
        'Internet penetration': 'Internet penetration',
        'Rank for number of users': 'Rank for number of users',
        'Bank account penetration': 'Bank account penetration',
        'Credit card penetration': 'Credit card penetration',
        'Borrowing habits': 'Borrowing habits'
      }
    },
    case: {
      title: 'Meet Melvie & Many Others',
      text: 'Many of the underbanked are hard-working. Our products and technology allow us to trust them where the traditional banks failed to. With this trust, they can have extra-funds to further expand their business, grow, invest or simply to overcome urgent needs.',
      button: 'Learn their stories'
    },
    about: {
      title: 'ABOUT SCOREONE',
      sub_title1: 'Our Visions & Missions & Goals',
      visions: 'Our Visions',
      v_text: 'The Fourth Industrial revolution is a great occasion to build trust. Big Data is the opportunity to redesign our definition of trust, in order to multiply potential client basis.',
      missions: 'Our Missions',
      m_text: 'We analyze thousands of variables to enable you to trust new clients and partners, regardless of their previous history.',
      goals: 'Our Goals',
      g_text: 'Improving business & financial inclusion, enhancing trust between partners based on Big Data analytics.',
      sub_title2: 'Our Team & Our Offices',
      button: 'Find Out More',
      sub_title3: 'Sustainability And Commitments',
      sac_text1: 'Improving all types of Financial inclusions',
      sac_text2: 'Creating the conditions for Trust to flourish',
      sac_text3: 'Increasing business interactions & capabilities',
      sac_text4: 'Harnessing Big Data to drive long-term growth',
      sac_text5: 'Guaranteeing Data Protection'
    }
  },
  TechServices: {
    application: {
      title: 'APPLICATION TO YOUR BUSINESS',
      ic_title: 'Identity Check',
      ic_text: 'If you need to verify the identity of your clients, we have the perfect solution for that. Using high-tech protocols, such as perspective correction, Optical Character Recognition and real-time data analysis, we can compare an ID with a real-time picture and confirm one’s identity within seconds.',
      fi_title: 'Fraud Identification',
      fi_text: 'Our anti-Fraud Live Detection Technology allows you to confidently interact with clients. Within a few seconds, a range of anti-spoofing measures, image analysis & facial recognition identify most accurately fraudulent behaviors, while protecting data privacy with encryption.',
      cs_title: 'Credit Score',
      cs_text: 'Thousands of variables can be analyzed by our algorithms in less than two minutes to determine whether a profile suits your business goals or fits your selected targets. A Credit Score is then attributed to an individual, representing its trustworthiness, despite lack of traditional information.'
    },
    main_content: {
      first_title: 'Trust more people, expand your customer basis.',
      first_text1: 'Trust is about two things: personal relations or sufficient proofs of trustworthiness.',
      first_text2: 'We provide cutting-edge technologies to allow you to trust clients or partners that you have never met and that never engaged with financial institutions before.',
      first_text3: 'The underbanked are people that are trapped in poverty. They don’t have enough money to engage in traditional banking services, and are thus usually forgotten. But being excluded from financial services doesn’t mean not being trustworthy: we help you to expend your client basis based on something different from traditional ways.',
      first_text4: 'With the opportunities offered by new technologies, Big Data and by the Internet of Things, we create a sustainable scheme allowing strangers that have been left behind by traditional banks to be trusted anyway, to become your clients or your partners.',
      second_title: 'HOW IT WORKS',
      second_text: "Over 5000 individual numerous variables are taken into account in order to assess one's credit score. From trivial information -age and gender- to the most sophisticated ones, like the travel occurrences and the mobility of the individuals, all the data are analyzed in less than two minutes. The state of art technology allow then to obtain a credit score tailored for each individual.",
      third_title: 'CONTINUOUS IMPROVEMENT',
      third_text: "Machine-learning & cutting-edge technologies Following the idea of machine-learning, our algorithm has been continuously learning from mistakes. It readjusts every second to ensure the technology's state of art."
    }
  },
  Sustainability: {
    main_content: {
      first_title: 'Empowering the underbanked',
      first_text: 'A third of the world population is ignored by traditional financial services, but the vast majority is connected to the Internet through their own smartphones. ScoreOne Technologies is striving to give people a credit score based on Big Data and other information, typically ignored by traditional financial services, in order to enhance financial inclusion across the developing world.',
      second_title: 'Committed to Sustainability',
      second_text: 'Financial inclusion allows people to access loans and thus potentially develop their own business, deal with emergencies & ultimately get out of the poverty trap. Helping people to be trusted for the first time by a financial service provider is paving the way for sustainable development, as it contributes to achieve the Sustainable Development Goals listed by the United Nations (SDGs).',
      third_title: 'Reasonable and responsible',
      third_text: 'Our algorithms are created to insure that trustworthy people can access services or goods traditionally out of their reach through their credit score. We emphasize on Machine-learning to avoid irresponsible lending. People that would have financially dangerous behaviors are typically excluded to avoid accumulation of debts & difficulties for our clients. Through the Credit Scoring technologies, we help our clients to offer the right services to the right people by avoiding risks and ensuring that no-one is offered a financial option that cannot be afforded in the longer term.'
    },
    bar_title: 'WE MADE IT POSSIBLE',
    users: {
      hint1: 'Public anomymity demanded.',
      hint2: 'For more information, contact us.',
      Melvie: 'Melvie is living in the Philippines. Like many local people, she cumulates two jobs. She is a local teacher and she also owns her car-wash business. Last year, thanks to the data services provided by ScoreOne Technologies, she was able to obtain a loan for the first time from our local partner. We analyzed hundreds of data and we could confirm that Melvie was trustworthy and hard working, while traditional financial institutions would reportedly keep their doors closed to her. Since then, she became one of our partner’s best clients. She managed to expand her business by buying additional machines and was able to quickly access money when her nephew was hospitalized to settle the bills. Big Data allowed her to be trusted and to become financially included and trusted, effectively improving her life conditions.',
      April: 'April has been one of our partners’ most loyal clients for 2 years. She is a teacher in a public school of the Philippines and her salary is usually delayed, which often involves financial difficulties. After seeing our partner’s targeted advertisement on a social media, she decided to apply. Her application was processed very fast. Big Data technologies identified she is a trustworthy borrower, as translated by her qualifying credit score. Money was quickly disbursed, allowing her to pay her bills without delays.',
      John: "John is a security guard and the owner of a small convenience store in the Philippines. He is proud to describe himself as a very hard working person. Banks would typically refuse his profile on the basis of his unstable income. Reflecting his impressive work ethics, our FinTech service quickly granted John a high credit score, allowing him to access Financial services for the first time. Empowering John with trust allows him to keep his store supplied when his salary is delayed, so that his business goes on and gains reputation. He recommended the our partner's app to his friends & family, emphasizing on the speed and reliability of the ser"
    }
  },
  Investor: {
    title: 'CLIENT SUCCESS',
    text1: 'Around the world, more and more people are gaining access to life-changing financial services thanks to our clients.',
    text2: 'We have enable our clients to obtain:',
    text3: '(in Who we are + Investors)',
    data_title1: 'Registered Users (Identity Confirmed)',
    data_title2: 'Loans were given after Credit Score assessment',
    data_title3: 'Total amount successfully lended',
    USD: 'USD',
    millions: 'millions',
    more_than: 'More than'
  },
  AboutUs: {
    introduce: {
      title: 'KNOW WHO WE ARE',
      sub_title1: 'Our Vision',
      v_text: 'ScoreOne Technology is envisioning a world in which people can be trusted because of their virtuous actions. We are working hard to create an alternative way to trust each other, to improve each party’s well-being. For the business, it’s about finding new reliable clients. For the individuals, it’s about getting access to services or goods that are legitimately deserved. ScoreOne is committed to sustainable development, enhancing financial inclusion by harnessing the potential of Big Data.',
      sub_title2: 'Our Mission',
      m_text: 'We are a fintech provider of alternative lending solutions for Credit Scoring, Fraud Detection & Identity Check. It brings innovations into emerging markets by supplying mobile financial services to the underbanked. The company’s products use cutting-edge algorithms, machine-learning & data sciences to provide real-time Credit Scoring & Lending-As-A-Service (LaaS) solutions to expand client basis for diverse business targets.'
    },
    corporateTeams: {
      title: 'Our Corporate Team',
      users: {
        BruceHuang: {
          name: 'Bruce Huang',
          position: 'President',
          details: 'Bruce Huang is the co-founder and president of ScoreOne Technology, a fintech company that provides Lending-as-a-Service (LaaS) for Southeast Asia consumer finance market. As a technology entrepreneur he has over 15 years working experience covering North America, China and Southeast Asia. He was a senior engineer lead at Microsoft in US for 7 years, an engineering director at Alibaba Cloud and the CTO of Yirendai at CreditEase Group in China, the first marketplace lending platform listed in US (NYSE: YRD). Before founding ScoreOne, he was the CEO of Madai Wealth, a top marketplace lending company in China.'
        }
      }
    },
    office: {
      title: 'Our Office'
    },
    figures: {
      title: 'Key Figures of ScoreOne',
      text1: 'Around the world, more and more people are gaining access to life-changing financial services thanks to our clients.',
      text2: 'We have enable our clients to obtain:',
      text3: '(last update: november 2019)',
      data_title1: 'Registered Users (Identity Confirmed)',
      data_title2: 'Loans were given after Credit Score assessment',
      data_title3: 'Total amount successfully lended',
      USD: 'USD',
      millions: 'millions',
      more_than: 'More than'
    }
  },
  Contact: {
    title: 'GET IN TOUCH',
    form: {
      name: 'Name：',
      name_required: 'The name is required !',
      email: 'Email：',
      email_required: 'The email is required !',
      message_required: 'The message is required !',
      subject: 'Subject：',
      hint: 'Type your message here：',
      choose: 'Please select',
      option1: 'Investment enquiries',
      option2: 'Solution enquiries',
      option3: 'About our company (media & PR)',
      submit: 'Submit',
      message: 'Submit successfully !'
    }
  }
}
