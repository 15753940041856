<template>
  <header>
    <div class="header">
      <div class="logo">
        <router-link to="/">
          <img src="./../assets/logo2.png" alt="ScoreOne" />
        </router-link>
        <h3>{{ $t('Header.logo_title') }}</h3>
      </div>
      <nav>
        <ul class="nav-menu">
          <li v-for="(item, index) in navData" :key="index">
            <router-link :to="item.router" :class="{ strong: $route.path.indexOf(item.name) !== -1 || item.name === 'home' && $route.path === '/' }">{{ $t(item.i18nTag) }}</router-link>
          </li>
        </ul>
      </nav>
      <div class="language-toggle">
        <span @mouseenter="isChangeLang = true" @mouseleave="isChangeLang = false">
          <span class="language-name" v-for="(item,index) in language[0]" :key="index">{{item}}</span>
          <img src="./../assets/triangle_bottom.png" alt />
          <ul v-show="isChangeLang">
            <li v-for="(item,index) in language" :key="index" v-show="index !== 0" @click="changeLanguage(item)">
              <span v-for="(val,ind) in item" :key="ind">{{val}}</span>
            </li>
          </ul>
        </span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function() {
    return {
      navData: [
        { name: 'home', router: '/home', i18nTag: 'Header.home' },
        { name: 'techservices', router: '/techservices', i18nTag: 'Header.tech_services' },
        { name: 'sustainability', router: '/sustainability', i18nTag: 'Header.sustainability' },
        { name: 'investors', router: '/investors', i18nTag: 'Header.investors' },
        { name: 'about', router: '/about', i18nTag: 'Header.about_us' },
        { name: 'contact', router: '/contact', i18nTag: 'Header.contact' }
      ],
      language: [{ en: 'English' }, { zh: '简体中文' }],
      isChangeLang: false
    }
  },
  methods: {
    isGetStrong(name) {
      return this.$route.path.indexOf(name) !== -1
    },
    changeLanguage(val) {
      // 语言菜单视图切换
      this.language.forEach((item, index) => {
        if (item === val) {
          this.language.splice(index, 1)
          this.language.unshift(val)
        }
      })
      this.isChangeLang = false

      // 语言切换
      for (const lang in val) {
        this.$i18n.locale = lang
      }
    }
  },
  beforeUpdate() {
    const { language } = this
    const { locale } = this.$i18n
    localStorage.lang = locale
    language.forEach((value, index) => {
      for (const lang in value) {
        if (locale === lang) {
          language.splice(index, 1)
          language.unshift(value)
        }
      }
    })
  }
}
</script>

<style scoped lang="scss">
.strong {
  font-weight: bold;
}
.indexStrong {
  font-weight: bold;
}
header {
  width: 100%;
  min-width: 1200px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  border-top: 1px solid #dedede;
  box-shadow: 0 0 8px #666;
  .header {
    width: 1200px;
    height: 102px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    .logo {
      position: absolute;
      left: 20px;
      height: 102px;
      text-align: center;
      img {
        margin-top: 7px;
        height: 60px;
      }
      h3 {
        margin-top: 8px;
        color: #000109;
        font-size: 12px;
        font-weight: 500;
      }
    }
    nav {
      display: inline-block;
      vertical-align: top;
      height: 102px;
      line-height: 102px;
      font-size: 14px;
      .nav-menu {
        display: flex;
        justify-content: space-between;
        li {
          padding: 0 25px;
          a {
            color: #000109;
            font-size: 14px;
            letter-spacing: 1px;
          }
        }
      }
    }
    .language-toggle {
      display: inline-block;
      right: 20px;
      position: absolute;
      height: 102px;
      line-height: 102px;
      font-size: 13px;
      color: #ff8711;
      span {
        padding-bottom: 7px;
        cursor: pointer;
        img {
          height: 8px;
          margin-left: 4px;
        }
        ul {
          margin: 0;
          padding: 0;
          position: absolute;
          top: 65px;
          left: -23px;
          width: 110px;
          font-size: 10px;
          background: #fff;
          border-radius: 5px;
          border: 1px solid #dedede;
          li {
            list-style: none;
            height: 32px;
            line-height: 32px;
            border-radius: 5px;
            overflow: hidden;
            &:hover {
              background: #efefef;
            }
          }
        }
      }
    }
    .mobile-nav {
        display: none;
        height: 24px;
        img {
            height: 100%;
        }
    }
  }
}
</style>
