export default {
  header: {
    logo_title: 'Data-driven micro-credit',
    home: 'Home',
    tech_services: 'Tech Services',
    sustainability: 'Sustainability',
    investor: 'Investor',
    about_us: 'About Us',
    contact: 'Contact'
  }
}
